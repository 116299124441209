import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgAskEmmCover from "../../assets/images/ask-emm-bg.png"
import imgMarsSurface from "../../assets/images/home/mars-surface.png"
import imgIcon1 from "../../assets/images/mission/about-mars/icon-(1).svg"
import imgIcon2 from "../../assets/images/mission/about-mars/icon-(2).svg"
import imgIcon3 from "../../assets/images/mission/about-mars/icon-(3).svg"
import imgIcon4 from "../../assets/images/mission/about-mars/icon-(4).svg"
import imgIcon5 from "../../assets/images/mission/about-mars/icon-(5).svg"
import imgEarth from "../../assets/images/mission/about-mars/earth.svg"
import imgMars from "../../assets/images/mission/about-mars/mars.svg"
import imgFactOne from "../../assets/images/home/fact-1-w.jpg"
import imgFactTwo from "../../assets/images/home/fact-2-w.jpg"
import imgFactThree from "../../assets/images/home/fact-3-w.jpg"
import imgFactFour from "../../assets/images/home/fact-4-w.jpg"
import imgFactFive from "../../assets/images/home/fact-5-w.jpg"
import imgFactSix from "../../assets/images/home/fact-6-w.jpg"
import imgFactSeven from "../../assets/images/home/fact-7-w.jpg"
import imgFactEight from "../../assets/images/home/fact-8-w.jpg"
import imgFactNine from "../../assets/images/home/fact-9-w.jpg"
import "./about-mars.css";

const AboutMarsPage = () => (
  <Layout lang="ar">
    <SEO title="About Mars" lang="ar" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40">
        <img src={imgAskEmmCover} alt="" style={{transform: "translate(50%,-50%)"}} data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section dir="rtl" id="about-emm" className="negative-margin-top-300 relative">
            <div className="standard-container-p0 pv4 ph5">
                <h2 className="relative uk-text-center">
                حول المريخ
                <div className="text-underline-heavy" style={{marginLeft: "auto", marginRight: "auto"}}></div>
                </h2>
                <div className="ph4-ns">
                  <div className="uk-position-relative uk-visible-toggle uk-light" data-tabindex="-1" data-uk-slider="clsActivated: uk-transition-active; center: true ">
                    <div className="uk-position-relative">
                      <div className="uk-slider-container uk-light">
                      <ul className="uk-slider-items uk-grid uk-grid-small">
                          <li className="uk-width-1-1">
                              <div className="uk-panel pa4-ns animated-text-trigger">
                                  <div className="animated-image-container">
                                      <img src={imgFactOne} alt=""/>
                                  </div>
                                  <div className="uk-position-center uk-text-center uk-position-center-rtl">
                                      <h2 className="fact-slider-heading">
                                        <span className="span-underline"> ثاني أصغر كوكب </span>في المجموعة الشمسية
                                      </h2>
                                      {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                                  </div>
                              </div>
                          </li>
                          <li className="uk-width-1-1">
                              <div className="uk-panel pa4-ns">
                                  <div className="animated-image-container">
                                      <img src={imgFactTwo} alt=""/>
                                  </div>
                                  <div className="uk-position-center uk-text-center uk-position-center-rtl">
                                      <h2 className="fact-slider-heading">

                                      <span className="span-underline">الكوكب الرابع في الترتيب</span><br/> من حيث البعد عن الشمس

                                      {/* <span className="span-underline"> الكوكب الرابع في </span>
                                       الترتيب من حيث 
                                      مسافة البعد عن الشمس */}
                                        </h2>
                                  </div>
                              </div>
                          </li>
                          <li className="uk-width-1-1">
                              <div className="uk-panel pa4-ns">
                                  <div className="animated-image-container">
                                      <img src={imgFactThree} alt=""/>
                                  </div>
                                  <div className="uk-position-center uk-text-center uk-position-center-rtl">
                                      <h2 className="fact-slider-heading">

                                         <span className="span-underline"> اليوم على المريخ </span>
                                         يزيد قليلاً عن<br /> 24 ساعة
                                      </h2>
                                      {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                                  </div>
                              </div>
                          </li>
                          <li className="uk-width-1-1">
                              <div className="uk-panel pa4-ns">
                                  <div className="animated-image-container">
                                      <img src={imgFactFour} alt=""/>
                                  </div>
                                  <div className="uk-position-center uk-text-center uk-position-center-rtl">
                                      <h2 className="fact-slider-heading">
                                        <span className="span-underline">السنة المريخية</span> تساوي <br/>687 يوماً
                                      </h2>
                                      {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                                  </div>
                              </div>
                          </li>
                          <li className="uk-width-1-1">
                              <div className="uk-panel pa4-ns">
                                  <div className="animated-image-container">
                                      <img src={imgFactFive} alt=""/>
                                  </div>
                                  <div className="uk-position-center uk-text-center uk-position-center-rtl">
                                      <h2 className="fact-slider-heading">
                                      المريخ
                                       <span className="span-underline"> كوكب صخري
                                        </span>
                                      </h2>
                                      {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                                  </div>
                              </div>
                          </li>
                          <li className="uk-width-1-1">
                              <div className="uk-panel pa4-ns">
                                  <div className="animated-image-container">
                                      <img src={imgFactSix} alt=""/>
                                  </div>
                                  <div className="uk-position-center uk-text-center uk-position-center-rtl">
                                      <h2 className="fact-slider-heading">
                                        <span className="span-underline" > لديه قمرين </span>
                                      يدوران حوله 
                                      </h2>
                                      {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                                  </div>
                              </div>
                          </li>
                          <li className="uk-width-1-1">
                              <div className="uk-panel pa4-ns">
                                  <div className="animated-image-container">
                                      <img src={imgFactSeven} alt=""/>
                                  </div>
                                  <div className="uk-position-center uk-text-center uk-position-center-rtl">
                                      <h2 className="fact-slider-heading">
                                      المريخ لديه <br/>
                                        <span className="span-underline"> غلافاً جوياً رقيقاً</span>
                                      </h2>
                                  </div>
                              </div>
                          </li>
                          <li className="uk-width-1-1">
                              <div className="uk-panel pa4-ns">
                                  <div className="animated-image-container">
                                      <img src={imgFactEight} alt=""/>
                                  </div>
                                  <div className="uk-position-center uk-text-center uk-position-center-rtl">
                                      <h2 className="fact-slider-heading">
                                      يظهر المريخ باللون الأحمر بسبب
                                        <span className="span-underline"> تأكسد</span> <br />
                                        <span className="span-underline">الحديد المنتشر في تربته</span>
                                      </h2>
                                      {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                                  </div>
                              </div>
                          </li>
                          <li className="uk-width-1-1">
                              <div className="uk-panel pa4-ns">
                                  <div className="animated-image-container">
                                      <img src={imgFactNine} alt=""/>
                                  </div>
                                  <div className="uk-position-center uk-text-center uk-position-center-rtl">
                                      <h2 className="fact-slider-heading">
                                      المريخ اليوم لا يمتلك
 <br/>
                                      <span className="span-underline">مجالاً مغناطيسياً</span></h2>
                                      {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                                  </div>
                              </div>
                          </li>
                        </ul>

                        <div className="uk-hidden@s uk-light uk-text-center">
                           <a className="uk-position-small custom-slide-nav" href="#" data-uk-slidenav-previous data-uk-slider-item="previous">السابق</a>
                            <a className="uk-position-small custom-slide-nav" href="#" data-uk-slidenav-next data-uk-slider-item="next">التالي</a>
                        </div>

                        <div className="uk-visible@s">
                            <a className="uk-position-center-left-out uk-position-small custom-slide-nav" href="#" data-uk-slidenav-previous data-uk-slider-item="previous">السابق</a>
                            <a className="uk-position-center-right-out uk-position-small custom-slide-nav" href="#" data-uk-slidenav-next data-uk-slider-item="next">التالي</a>
                        </div> 
                      </div>      
                    </div>
                        {/* <div className="flex justify-center uk-margin">
                          <ul className="uk-slider-nav uk-flex-center uk-margin custom-dot-nav">
                            <li data-uk-slider-item="0"><a href="#">01</a></li>
                            <li data-uk-slider-item="1"><a href="#">02</a></li>
                            <li data-uk-slider-item="2"><a href="#">03</a></li>
                            <li data-uk-slider-item="3"><a href="#">04</a></li>
                            <li data-uk-slider-item="4"><a href="#">05</a></li>
                          </ul> 
                        </div>  */}
                  </div>
                </div>
            </div>
            <div className="standard-container standard-bg negative-margin-top-250" style={{zIndex: "-1"}}></div>
            <div className="large-container pb6">
            <div className="standard-container standard-bg">
              <div className="ph4-ns">
              <p className="mt4">سماه الرومان تيمناً باسم إله الحرب لديهم وذلك بسبب لونه الأحمر الذي يشبه الدم. وهو رابع كوكب في الترتيب من حيث البعد عن الشمس. وهو كوكب صحراوي يمتاز ببرودته الشديدة وجوه المغبر، كما أن غلافه الجوي رقيق للغاية. ويتشابه المريخ مع الأرض في كثير من المعالم مثل تغير فصول السنة، والثلوج التي تغطي القطبين، وتغير الطقس وانتشار الأخاديد والجبال والبراكين الخامدة ومميزات أخرى يمكن العثور عليها في كوكبنا.</p>
              <p>يُعد المريخ أكثر جسم فضائي تم استكشافه في مجموعتنا الشمسية، حيث أرسل البشر الكثير من المسابير والمستكشفات ومنصات الهبوط لاستكشاف الكوكب الأحمر وإزالة الغموض عن أسراره. وكما تعلمنا الكثير من بعثاتنا السابقة، يتبقى الكثير أيضاً لنتعلمه عن تاريخ المريخ وحاضره ومستقبله. </p>
              <p>ولقد أمدّنا بحثنا حتى الآن بأدلة وافرة على أن كوكب المريخ كان يوماً ما كوكباً أكثر دفئاً ورطوبة، ويمتلك غلافاً جوياً أكثر سُمكاً. وهنا يتبادر الى أذهاننا سؤال مهم وهو ما الذي حدث على مدار مليارات السنين وأدى إلى تحول المريخ إلى كوكب جاف؟ وتكمن الطريقة الوحيدة لاكتشاف ما حدث هي الدراسة المتعمقة للغلاف الجوي لكوكب المريخ، وهو الأمر الذي سيساعدنا كثيراً ليس على الذهاب للمريخ فقط، ولكن أيضاً على فهمنا كبشر لكوكب الأرض ومستقبله. </p>
              <div className="pv4">
                <div className="flex flex-column flex-row-ns pv3">
                    <div className="w-100 w-20-ns pt2">
                        <img src={imgIcon3} className="w-60" alt=""/>
                    </div>
                    <div className="w-100 w-80-ns pt3">
                        <p className="uk-text-lead text-highlight-brown uk-text-bold">
                        الغلاف الجوي للمريخ
                        </p>
                        <p className="uk-text-meta">
                        يتكون الغلاف الجوي للمريخ من غازات ثاني أكسيد الكربون والنيتروجين والأرجون، وهو رقيق للغاية، ونتيجة لذلك فإنه لا يتمكن من حماية سطح الكوكب من خطر النيازك والكويكبات والمذنبات. وهو ما يتسبب أيضاً في هروب حرارة الشمس بسهولة من الكوكب، ويمكن أن تتراوح درجات الحرارة بين 20 و-153 درجة مئوية. ولتوضيح ذلك أكثر تخيل أنك تقف على سطح المريخ عند خط الاستواء في وقت الظهيرة، فستشعر عند قدميك كأنك في فصل الربيع (24 درجة مئوية) وعند رأسك ستشعر أنك في الشتاء (صفر درجة مئوية). كما أن المريخ يشهد أحياناً رياحاً شديدة تُسبب عواصف ترابية يمكنها أن تُغطي الكوكب لشهور.
                        </p>
                    </div>
                </div>
                <hr className="custom-hr" style={{borderColor: "black"}} />
                <div className="flex flex-column flex-row-ns pv3">
                    <div className="w-100 w-20-ns pt3">
                        <img src={imgIcon5} className="w-60" alt=""/>
                    </div>
                    <div className="w-100 w-80-ns pt3">
                        <p className="uk-text-lead text-highlight-brown uk-text-bold">
                        حجمه وبعده عن الشمس
                        </p>
                        <p className="uk-text-meta">
                        يبلغ نصف قطر كوكب المريخ حوالي 3,390 كيلومتر ويصل حجمه إلى نصف حجم الأرض. ويبلغ متوسط المسافة بين المريخ والشمس حوالي 228 مليون كيلومتر، وهي المسافة التي تُقدر بحوالي 1.5 وحدة فلكية. (الوحدة الفلكية هي المسافة بين الأرض والشمس وتُعرف اختصاراً بـ “و.ف”)
                        </p>
                    </div>
                </div>
                <hr className="custom-hr" style={{borderColor: "black"}} />
                <div className="flex flex-column flex-row-ns pv3">
                    <div className="w-100 w-20-ns pt3">
                        <img src={imgIcon1} className="w-60" alt=""/>
                    </div>
                    <div className="w-100 w-80-ns pt3">
                        <p className="uk-text-lead text-highlight-brown uk-text-bold">
                        بنية الكوكب
                        </p>
                        <p className="uk-text-meta">
                        يمتلك كوكب المريخ نواة كثيفة في مركزه على عمق يتراوح بين 1500 إلى 2100 كيلومتر. وتتكون هذه النواة في معظمها من الحديد والنيكل والكبريت. وهناك غلاف صخري يُحيط بالنواة يتراوح سمكه بين 1240 إلى 1800 كيلومتر. ويغطي هذا كله قشرة مكونة من الحديد والمغنيسيوم والألمونيوم والكالسيوم والبوتاسيوم ويتراوح عمق هذه القشرة بين 10 إلى 50 كيلومتر.
                        </p>
                    </div>
                </div>
                <hr className="custom-hr" style={{borderColor: "black"}} />
                <div className="flex flex-column flex-row-ns pv3">
                    <div className="w-100 w-20-ns pt3">
                        <img src={imgIcon2} className="w-60" alt=""/>
                    </div>
                    <div className="w-100 w-80-ns pt3">
                        <p className="uk-text-lead text-highlight-brown uk-text-bold">
                        السطح
                        </p>
                        <p className="uk-text-meta">
                        يتكون سطح المريخ من ألوان عدة كالذهبي والبني والأسمر، لكن المريخ يظهر لنا باللون الأحمر نتيجة لتأكسد الحديد المنتشر بكثافة في صخور المريخ مكوناً جزيئات من الصدأ وبفعل الرياح ترتفع هذه الجزيئات وتنتشر في الغلاف الجوي وهو ما يجعل الكوكب يبدو أحمر اللون عند النظر إليه من بعيد. ومن المثير للاهتمام أنه على الرغم من أن قطر المريخ يساوي نصف قطر الأرض إلا أن مساحة المريخ تساوي مساحة اليابسة على الأرض.
                        </p>
                    </div>
                </div>
                <hr className="custom-hr" style={{borderColor: "black"}} />
                <div className="flex flex-column flex-row-ns pv3">
                    <div className="w-100 w-20-ns pt3">
                        <img src={imgIcon4} className="w-60" alt=""/>
                    </div>
                    <div className="w-100 w-80-ns pt3">
                        <p className="uk-text-lead text-highlight-brown uk-text-bold">
                        الأقمار
                        </p>
                        <p className="uk-text-meta">
                        يمتلك كوكب المريخ قمرين صغيرين، هما فوبوس وديموس، ويعتقد العلماء أن القمرين كانا مجرد كويكبين استطاع المريخ أن يجذبهما إليه. ويكتسب القمران شكل حبة البطاطس لأن كتلتهما صغيرة جداً لذلك لا تستطيع الجاذبية أن تجعلهما كرويين. واكتسب القمران اسميهما من الخيول التي تجر عربة “آريس” إله الحرب عند اليونانيين، حيث يُسمى الأول فوبوس أي الخوف، بينما يُسمي الثاني ديموس أي الرعب.
                        </p>
                    </div>
                </div>   
                </div>
              </div>
              
              <div className="about-mars-table">
                
                <table className="uk-table uk-table-responsive  uk-table-divider uk-table-small">
                    <tbody className="text-white">
                        <tr style={{paddingBottom: "40px"}}>
                            <td>
                            <div className="animated-tab-head about-mars-tab-head-ar uk-text-center uk-text-right@s">
                                <div>
                                <h2 className="text-white uk-text-uppercase pv3">
                                مقارنة بين الأرض والمريخ
                                </h2>
                                </div>
                            </div>
                            </td>
                            <td className=" uk-text-center uk-text-right@s">
                                <img src={imgEarth} alt=""/>
                            </td>
                            <td className=" uk-text-center uk-text-right@s">
                                <img src={imgMars} alt=""/>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-highlight-brown">
                            متوسط المسافة من الشمس
                            </td>
                            <td>
                            <span className="uk-hidden@s">الأرض : </span>
                            93 مليون ميل
                            </td>
                            <td>
                            <span className="uk-hidden@s">المريخ : </span>
                            142 مليون ميل
                            </td>
                        </tr>
                        <tr>
                            <td className="text-highlight-brown">
                            متوسط سرعة الدوران حول الشمس
                            </td>
                            <td>
                            <span className="uk-hidden@s">الأرض : </span>
                            18.5 ميل في الثانية
                            </td>
                            <td>
                            <span className="uk-hidden@s">المريخ : </span>
                            14.5 ميل في الثانية
                            </td>
                        </tr>
                        <tr>
                            <td className="text-highlight-brown">
                            نصف قطر الأرض
                            </td>
                            <td>
                            <span className="uk-hidden@s">الأرض : </span>
                            7,926 ميل
                            </td>
                            <td>
                            <span className="uk-hidden@s">المريخ : </span>
                            4,220 ميل
                            </td>
                        </tr>
                        <tr>
                            <td className="text-highlight-brown">
                            ميلان محور الدوران
                            </td>
                            <td>
                            <span className="uk-hidden@s">الأرض : </span>
                            23.5 درجة
                            </td>
                            <td>
                            <span className="uk-hidden@s">المريخ : </span>
                            25 درجة
                            </td>
                        </tr>
                        <tr>
                            <td className="text-highlight-brown">
                            عدد أيام السنة
                            </td>
                            <td>
                            <span className="uk-hidden@s">الأرض : </span>
                            365.25 يوماً
                            </td>
                            <td>
                            <span className="uk-hidden@s">المريخ : </span>
                            687 يوماً أرضياً
                            </td>
                        </tr>
                        <tr>
                            <td className="text-highlight-brown">
                            عدد ساعات اليوم
                            </td>
                            <td>
                            <span className="uk-hidden@s">الأرض : </span>
                            23 ساعة و56 دقيقة
                            </td>
                            <td>
                            <span className="uk-hidden@s">المريخ : </span>
                            24 ساعة و37 دقيقة
                            </td>
                        </tr>
                        <tr>
                            <td className="text-highlight-brown">
                            الجاذبية
                            </td>
                            <td>
                            <span className="uk-hidden@s">الأرض : </span>
                            2.66 من الجاذبية على المريخ
                            </td>
                            <td>
                            <span className="uk-hidden@s">المريخ : </span>
                            0.357 من الجاذبية على الأرض
                            </td>
                        </tr>
                        <tr>
                            <td className="text-highlight-brown">
                            الحرارة
                            </td>
                            <td>
                            <span className="uk-hidden@s">الأرض : </span>
                            57 درجة فهرنهايت في المتوسط
                            </td>
                            <td>
                            <span className="uk-hidden@s">المريخ : </span>
                            -81 درجة فهرنهايت في المتوسط
                            </td>
                        </tr>
                        <tr>
                            <td className="text-highlight-brown">
                            مكونات الغلاف الجوي
                            </td>
                            <td>
                            <span className="uk-hidden@s">الأرض : </span>
                            النيتروجين والأكسجين والأرجون وغازات أخرى
                            </td>
                            <td>
                            <span className="uk-hidden@s">المريخ : </span>
                            يتكون أغلبه من ثاني أكسيد الكربون وبعض بخار الماء
                            </td>
                        </tr>
                        <tr>
                            <td className="text-highlight-brown">
                            عدد الأقمار
                            </td>
                            <td>
                            <span className="uk-hidden@s">الأرض : </span>
                            1
                            </td>
                            <td>
                            <span className="uk-hidden@s">المريخ : </span>
                            2
                            </td>
                        </tr>
                    </tbody>
                </table>
              </div>
            </div>         
          </div>  
      </section>
    </main>
  </Layout>
)

export default AboutMarsPage
